body {
	background-color: #eee;
}
header {
	/* background-image: url(../images/bg-header.jpg); */
	background-size: auto 116px;
	width: 100%;
	color: #fff;
	height:116px;
	position: absolute;
	z-index: 1;
}

footer {
	border-top: 1px solid #ddd;
	font-size: .8em;
	line-height: 140%;
	width: 100%;
}

footer .footer-text {
	/*margin: 28px 25px;*/
	float: center;
}

.btn-back {
	padding: 3px 6px;
	position:absolute;
	top:30px
}

.ver-resultados {
	/*font-size:16px;*/
	padding:10px 20px;
	border-radius:0 0 5px 5px;
	border-top:none;
	border-width:1px;
	font-family:arial;
	line-height:100%;
	/*background-color: #ffaa00;*/
	color:#444;
	height:90px;
	top:86px;
	position:absolute;
	/*------------------*/
	font-size:14px;
	font-weight: bold;
	background-color: #fff;
	text-transform: uppercase;
	font-display: swap
}



.drop-shadow {
	box-shadow: 0 2px 10px #222;
}

@media screen and (max-width:807px) {
	footer .footer-text {
		float: none;
		text-align: center;
	}
}

.logo {
	display:inline-block;
	height:60px;
	margin-top:20px;
}

.main-page {
	background-color: #fff;
	position: relative;
	padding-bottom: 50px
}

.latest-stats {
	background-color: #64c43b;
	color: #fff;
	position: relative;
	padding: 30px;
	margin-top: 20px
}

.latest-stats .stats-holder h3 {
	text-align: center;
	margin: 0 0 15px;
	padding: 0
}

.latest-stats .stats-holder ul {
	padding: 0;
	margin: 0;
	list-style: none
}

.latest-stats .stats-holder ul li {
	padding: 10px;
	border-bottom: 1px solid #fff
}

.latest-stats .stats-holder ul li:last-child {
	border-bottom-color: transparent
}

.latest-stats .stats-holder .list-dts table {
	background-color: transparent
}

.latest-stats .stats-holder .list-dts table tbody tr td {
	border: none;
	border-bottom: 1px solid #fff;
	padding: 17px 10px;
	vertical-align: middle
}

.latest-stats .stats-holder .list-dts table tbody tr:last-child td {
	border-bottom-color: transparent
}

.latest-stats .stats-holder .pos-numb {
	background-color: #3a344e;
	width: 30px;
	height: 30px;
	font-weight: 700;
	color: #fff;
	display: inline-block;
	margin-right: 15px;
	-webkit-border-radius: 40px;
	-moz-border-radius: 40px;
	border-radius: 40px;
	text-align: center;
	line-height: 30px
}

.latest-stats .stats-holder .btn {
	font-size: .7em;
	padding: 4px
}

.latest-stats .camiseta {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 30px;
	height: 30px;
	margin: 0 10px 0 0;
	position: relative;
	top: 8px;
	display: inline-block;
	border:1px solid #bbb;
}

.seleccionar-formacion {
	text-align: center;
	padding:10px;
	color:#777;
}

.seleccionar-formacion .opciones-formacion .radio-inline-input {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute!important
}

.seleccionar-formacion .opciones-formacion .radio-inline-label {
	display: inline-block;
	padding: 12px 14px;
	margin-right: 18px;
	transition: all .2s;
	font-size: 1.5em;
	cursor: pointer;
	opacity: .3
}

.seleccionar-formacion .opciones-formacion .radio-inline-input:checked+.radio-inline-label {
	background: #072748;
	color: #fff;
	opacity: 1
}

.seleccionar-formacion .opciones-formacion .radio-inline-input:focus+.radio-inline-label {
	outline: 0
}

#logo-club {
	height: 90px
}

@media screen and (min-width:480px) {
	#logo-club {
		position:absolute;
		left:50%;
		height: auto
	}
}

.seleccione-categoria {
	text-shadow:0 0 5px #000, 0 0 4px #000, 0 0 3px #000,0 0 2px #000;
	text-transform: uppercase;
	color:#fff;
	font-weight: bold !important;
}

#cancha {
	background-image: url(../assets/imagenes/fans-septiembre/generales/grupo_153.jpg);
} 

/* #imagen_cancha{
	background-image: url(../assets/imagenes/fans-septiembre/grupo_153.jpg);
	opacity: 0.6;
} */
@media (min-width:780px) {
	#cancha {
		background-image: url(../assets/imagenes/fans-septiembre/generales/canchaacostada2.png);
	} 
	#cancha, #imagen_cancha {
		margin-top: -15rem !important;
	}	
}

#cancha, #imagen_cancha {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 880px;
	height: 876px;
	margin: 10px auto;
	position: relative;
	/*top:-60px;*/
}

#cancha .jugador {
	width: 120px;
	height: 100px;
	text-align: center;
	position: absolute;
	top: 0;
	left: 0;
	-webkit-transition: .2s ease;
	-moz-transition: .2s ease;
	transition: .2s ease
}

#cancha .jugador .camiseta {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	width: 60px;
	height: 60px;
	margin: 0 auto;
	position: relative;
	z-index: 3;
	border-radius: 30px;
	border:1px solid rgba(187, 187, 187, 0);
}

#cancha .jugador .seleccionar-jugador {
	position: relative;
	background-color: #fff;
	width: auto;
	width: 100%;
	-webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .2);
	-moz-box-shadow: 0 3px 8px rgba(0, 0, 0, .2);
	box-shadow: 0 3px 8px rgba(0, 0, 0, .2);
	z-index: 4
}

#cancha .jugador .seleccionar-jugador select {
	font-size: .65em;
	font-weight: 700;
	width: 100%;
	padding: 8px 6px;
	text-transform: uppercase;
	border: none;
	background-color: transparent;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	position: relative;
	z-index: 3;
	line-height: 120%
}

#cancha .jugador .seleccionar-jugador select:active,
#cancha .jugador .seleccionar-jugador select:focus {
	outline: 0;
	box-shadow: none
}

#cancha .jugador .seleccionar-jugador:after {
	content: " ";
	position: absolute;
	top: 50%;
	margin-top: -2px;
	right: 8px;
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 5px solid #aaa;
	z-index: 2
}

#cancha .jugador .nombre-jugador {
	font-size: .7em;
	font-weight: 700;
	/*background-color: #072748;*/
	padding: 4px 6px;
	display: inline-block;
	text-align: center;
	text-shadow: 0 0 3px #000;
	color: #fff;
	text-transform: uppercase;
	position: relative;
	z-index: 4;
	line-height: 90%;
}

.cancha-top {
	width: 50%;
	margin: 10px auto
}

.cancha-top h2 {
	margin: 0 0 15px;
	padding: 0;
	font-size: 1.6em;
	line-height: 100%;
	overflow: hidden
}

.cancha-top h2 span {
	display: block;
	font-size: .6em;
	font-weight: 400
}

.cancha-top .compartir-equipo {
	text-transform: uppercase;
	text-align: right;
	margin-top: 20px
}

.cancha-top .compartir-equipo strong {
	padding-right: 10px;
	position: relative;
	top: -1px
}

.cancha-top .compartir-equipo a {
	font-size: 1.4em;
	display: inline-block;
	padding: 7px
}

.cancha-top .compartir-equipo a:focus,
.cancha-top .compartir-equipo a:hover {
	color: #64c43b
}

.cancha-foot {
	width: 70%;
	margin: 10px auto
}

.cancha-foot .nombre-dt label {
	float: left;
	padding-top: 15px
}

.cancha-foot .nombre-dt input {
	-webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .2);
	-moz-box-shadow: 0 3px 8px rgba(0, 0, 0, .2);
	box-shadow: 0 3px 8px rgba(0, 0, 0, .2);
	padding: 12px 16px;
	font-size: 1.1em;
	font-weight: 700;
	border: 1px solid #eee;
	outline: 0;
	text-align: center;
	text-transform: uppercase
}

.cancha-foot .btn-siguiente {
	float: right;
	text-transform: uppercase;
	outline: 0
}

.cancha-foot .btn-comenzar {
	float: right;
	text-transform: uppercase;
	outline: 0;
	margin-top: 15px
}

.cancha-foot .comenzar-title {
	line-height: 110%;
	margin: 0;
	padding: 0;
	font-size: 2em
}

.cancha-foot .comenzar-title span {
	font-size: .5em;
	display: block
}

@media screen and (max-width:992px) {
	.cancha-top {
		width: 65%
	}
	#cancha, #imagen_cancha {
		width: 700px;
		height: 720px
	}
	
}

@media screen and (max-width:767px) {
	/* #cancha {
		background-image: url(../images/cancha-vertical.svg);
	} */
	#imagen_cancha {
		/* background-image: url(../images/cancha-vertical-low.jpg); */
		opacity: 0.6;
	}
	#cancha, #imagen_cancha {
		top:0;
		width: 450px;
		height: 680px
	}
	.cancha-top {
		text-align: center;
		width: 100%
	}
	.cancha-top .compartir-equipo {
		text-align: center;
		margin-top: 10px;
		margin-bottom: 20px
	}
	.cancha-foot {
		text-align: center
	}
	.cancha-foot .btn-comenzar,
	.cancha-foot .btn-siguiente,
	.cancha-foot .nombre-dt label {
		float: none
	}
	.cancha-foot .comenzar-title {
		line-height: 85%;
		margin-top: 10px;
		margin-bottom: 10px
	}
	.seleccionar-formacion {
		margin-bottom: 25px
	}
	.seleccionar-formacion .opciones-formacion .radio-inline-label {
		padding: 8px 10px;
		font-size: 1.3em;
		margin-right: 5px
	}
}

@media screen and (max-width:480px) {
	.seleccionar-formacion h3 {
		font-size: 1.2em;
	}
	.seleccionar-formacion .opciones-formacion .radio-inline-label {
		padding: 8px 7px;
		font-size: 1.2em;
		margin-right: 0
	}
	.cancha-foot {
		width: 100%
	}
	.cancha-foot .comenzar-title {
		font-size: 1.7em
	}
	#cancha, #imagen_cancha {
		width: 360px;
        height: 570px;
	}
	#cancha .jugador {
		width: 75px;
		height: 80px
	}
	#cancha .jugador .camiseta {
		width: 60px;
		height: 60px;
		background-position: top center;
		background-size: 40px 40px;
	}

	.ju22{
		margin: 1rem;
		font-weight: bold;
		color: white
	}
	#cancha .jugador.chupin .camiseta:after {
		width: 14px;
		height: 24px
	}
	#cancha .jugador .seleccionar-jugador {
		line-height: 100%
	}
	#cancha .jugador .seleccionar-jugador:after {
		border-left: 2px solid transparent;
		border-right: 2px solid transparent;
		border-top: 3px solid #aaa
	}
	#cancha .jugador .seleccionar-jugador select {
		font-size: .4em;
		padding: 4px;
		top: -2px
	}
	#cancha .jugador .nombre-jugador {
		font-size: .5em;
		line-height: 100%
	}
}
.ju22{
	margin: 1rem;
	font-weight: bold;
	color: white
}
